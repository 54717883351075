@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: sans-serif;
}

body {
  background-color: transparent;
}

#root {
  background-color: transparent;
}
